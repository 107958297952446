@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import url('./bootstrap.min.css');

html {
    font-size: 100% !important;
}

.simple-nav {
    list-style: none !important;
    padding: 10px 15px;
    font-size: 1.1rem;
}

.simple-nav li:not(first-child) {
    margin-bottom: 10px;
}

body {
    font-family: 'Open Sans', sans-serif !important;
    background: #fff !important;
}

body {
    color: #555 !important;
}

body p {
    font-size: 1.1rem;
}

.vcenter {
    display: inline-block !important;
    vertical-align: middle !important;
    float: none !important;
}

#root > .main {
    padding: 20px 30px !important;
    background: #fafafa !important;
}

h1, h2 {
    margin-bottom: 1.5rem !important;
}

/* Journal styles */
.journal-items {
    margin: 1rem 0;
    padding: 1rem 0;
    border-top: 2px solid #5ac0de;
    max-height: 50vh;
    overflow-y: auto;
}

.journal-item {
    padding: 1rem;
    font-size: 1.2rem;
    color: #4c89a7;
    font-weight: 700;
}

.journal-item:not(last-child) {
    border-bottom: 2px dashed #e6e6e6;
}

.journal-new--input {
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
    border:  2px solid #5ac0de;
    color: #5b8c9a; 
    font-size: 1.2rem;
}